<template>
  <div>
    <el-card>
      <div>
        <buttonPermissions :datas="'CustomerTypeAdd'">
          <el-button type="primary" @click="edit({Id:0})">添加类型</el-button>
        </buttonPermissions>
      </div>
      <el-table :data="tablelist" v-loading="tableloading" class="margin-top-10">
        <el-table-column prop="TypeName" label="客户类型"></el-table-column>
        <el-table-column prop="AddTime" label="添加时间"></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <div class="flex gap-10">
              <buttonPermissions :datas="'CustomerTypeAdd'">
                <el-button type="text" @click="edit(scope.row)">编辑</el-button>
              </buttonPermissions>
              <buttonPermissions :datas="'CustomerTypeDel'">
                <el-button type="text" style="color:#f56c6c" @click="toDel(scope.row)">删除</el-button>
              </buttonPermissions>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination style="text-align:right" class="margin-top-10" v-if="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>

    <el-dialog :visible.sync="editShow" :title="`${rowmsg.Id?'编辑':'添加'}客户类型`" width="500px" custom-class="dialog-body-paddingTop-10">
      <div class="form" style="margin-top:0">
        <div class="flex flex-align-center">
          <div class="form-label label-star">类型名称：</div>
          <el-input v-model="rowmsg.TypeName" placeholder="类型名称" maxlength="50"></el-input>
        </div>
      </div>
      <div class="flex flex-align-center gap-10 margin-top-20">
        <el-button style="margin-left:auto" @click="editShow=false">关闭</el-button>
        <el-button type="primary" @click="confirmEdit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  crmCustomerTypelist,
  crmCustomerTypedelete,
  crmCustomerTypesave
} from "@/api/sv3.0.0"
import buttonPermissions from '@/components/buttonPermissions';
export default {
  components: {
    buttonPermissions
  },
  data () {
    return {
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      rowmsg:{},
      editShow:false,
    }
  },
  mounted () {
    this.gettablelist()
  },
  methods: {
    toDel(row){
       this.$confirm('删除类型后，对应客户的类型将会清空。该操作不可恢复，是否确认删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '关闭',
          type: 'warning'
        }).then(async () => {
          try{
            let res = await crmCustomerTypedelete({
              Id:row.Id
            })
            if(res.IsSuccess){
              this.$message.success('操作成功')
              this.gettablelist()
            }
          }finally{
            //
          }
        })
    },
    async confirmEdit(){
      if(!this.rowmsg.TypeName){
        this.$message.error('请输入类型名称')
        return 
      }
      try{
        let res = await crmCustomerTypesave(this.rowmsg)
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.editShow = false
          this.gettablelist()
        }
      }finally{
        //
      }
    },
    edit(row){
      this.rowmsg = JSON.parse(JSON.stringify({
        ...row,
        TypeName:row.TypeName||''
      }))
      this.editShow = true
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res = await crmCustomerTypelist({
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize
        })
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    handleSizeChange(e){
      this.pagesize = e
      this.currentPage = 1
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    }, 
  }
}
</script>

<style lang='less' scoped>

</style>